import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby'
import CommonHero from '../components/commonhero/commonhero.js'
import '../pages/index.css'
import NordykeImage from '../images/Nordyke.jpeg' // Import directly

export default function ClientStoryTemplate({ pageContext }) {
  const { title, content, imageAlt, id } = pageContext

  // Convert content string with newlines to array of paragraphs
  const paragraphs = content.split('\n\n')

  // Get the appropriate image based on the story ID
  const getStoryImage = storyId => {
    if (storyId === 'jc-heather-nordyke') {
      return NordykeImage
    }
    return null
  }

  const storyImage = getStoryImage(id)

  return (
    <Layout head={{ title: title }}>
      <CommonHero pageTitle={title} />
      <div className="container px-5 mx-auto mt-5 mb-5">
        <div className="row align-items-start box-shadow p-4">
          {/* Mobile image - visible only on mobile */}
          <div className="col-12 d-block d-lg-none mb-4">
            <div className="client-image-container">
              {storyImage && (
                <>
                  <img
                    src={storyImage}
                    alt={imageAlt}
                    className="img-fluid rounded shadow"
                    style={{
                      width: '100%',
                      maxHeight: '600px',
                      objectFit: 'cover',
                    }}
                  />
                  <div className="image-caption mt-3 text-center">
                    <p className="font-italic">{imageAlt}</p>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Content column */}
          <div className="col-12 col-lg-6 mb-5 mb-lg-0">
            <div className="client-story-content">
              {paragraphs.map((paragraph, i) => (
                <p key={i} className="mb-4">
                  {paragraph}
                </p>
              ))}
              <div className="position-relative mb-3 mt-5">
                <a
                  href="tel:7194599955"
                  className="alink hvr-shadow my-3 text-center primary-button btn-before border-none"
                >
                  Call Us at (719) 459-9955
                </a>
              </div>
              <a
                href="mailto:brianboals@gmail.com"
                className="alink hvr-shadow text-center secondary-button btn-before"
                role="button"
              >
                Email Us at brianboals@gmail.com
              </a>
            </div>
          </div>

          {/* Desktop image - visible only on desktop */}
          <div className="col-lg-6 d-none d-lg-block h-100">
            <div className="client-image-container">
              {storyImage && (
                <>
                  <img
                    src={storyImage}
                    alt={imageAlt}
                    className="img-fluid rounded shadow"
                    style={{
                      width: '100%',
                      maxHeight: '600px',
                      objectFit: 'cover',
                    }}
                  />
                  <div className="image-caption mt-3 text-center">
                    <p className="font-italic">{imageAlt}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .client-story-content {
          font-size: 1.1rem;
          line-height: 1.8;
        }
        .box-shadow {
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
          border-radius: 8px;
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
        @media (max-width: 992px) {
          .client-image-container {
            margin-bottom: 1rem;
          }
        }
      `}</style>
    </Layout>
  )
}
